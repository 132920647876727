.invest-relation {
  padding-top: 58px;
  .invest-hope {
    padding: 100px 280px 199px 285px;
    background: url('../../images/hope-bg.png') no-repeat;
    background-size: 100% 100%;
    .hope-title {
      font-size: 32px;
      color: #202023;
      font-weight: 500;
      line-height: 42px;
      text-align: center;
    }
    .hope-item {
      display: flex;
      justify-content: space-between;
      margin-top: 95px;
      img {
        width: 456px;
        height: 476px;
      }
    }
  }
  .course-box {
    position: relative;
    .course-title {
      position: absolute;
      top: 100px;
      left: 0;
      width: 100%;
      font-size: 32px;
      color: #202023;
      font-weight: 500;
      line-height: 42px;
      text-align: center;
    }
  }
  .shujv-caiwu {
    padding: 94px 360px 357px 268px;
    background: url('../../images/yunyingshujv.png') no-repeat;
    background-size: 100% 100%;
    .shujv-title {
      font-size: 32px;
      color: #ffffff;
      font-weight: 500;
      line-height: 42px;
      text-align: center;
    }
    .shujv-item {
      display: flex;
      justify-content: space-between;
      margin-top: 85px;
      img {
        width: 270px;
        height: 450px;
      }
    }
  }
  .gudong-beijing {
    padding: 100px 360px 86px;
    background: #f5faff;
    .beijing-title {
      font-size: 32px;
      color: #202023;
      font-weight: 500;
      line-height: 42px;
      text-align: center;
    }
    .compone-desc {
      margin-top: 63px;
      .lvdijituan {
        display: flex;
        justify-content: space-between;
        .left {
          margin-right: 90px;
          img {
            margin-bottom: 29px;
            width: 149px;
            height: 43px;
          }
          p {
            font-size: 14px;
            color: #282938;
            line-height: 28px;
          }
        }
        .right {
          width: 600px;
          height: 400px;
        }
      }
      .lvdishuke {
        display: flex;
        justify-content: space-between;
        margin-top: 90px;
        .left {
          img {
            margin-bottom: 29px;
            width: 149px;
            height: 43px;
          }
          p {
            font-size: 14px;
            color: #282938;
            line-height: 28px;
          }
        }
        .right {
          margin-right: 60px;
          width: 600px;
          height: 400px;
        }
      }
    }
  }
}
