.footer {
  padding: 56px 370px 76px 370px;
  background: #000000;
  // height: 501px;
  width: 100%;
  .contact-us {
    font-size: 24px;
    color: #ffffff;
  }
  .company-info {
    display: flex;
    justify-content: space-between;
    .info-container {
      display: flex;
      margin-top: 55px;
      align-items: center;
    }
    .service-left {
      margin-right: 71px;
      .service-hotline {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        span {
          &:nth-child(1) {
            padding-bottom: 15px;
          }
          &:nth-child(2) {
            color: #ffffff;
          }
        }
      }
      .contact-hotline {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        font-size: 14px;
        span {
          &:nth-child(1) {
            padding-bottom: 15px;
          }
          &:nth-child(2) {
            color: #ffffff;
          }
        }
      }
    }
    .service-middle {
      .company-email {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        span {
          &:nth-child(1) {
            padding-bottom: 15px;
          }
          &:nth-child(2) {
            color: #ffffff;
          }
        }
      }
      .contact-address {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        font-size: 14px;
        span {
          &:nth-child(1) {
            margin-bottom: 15px;
          }
          &:nth-child(2) {
            color: #ffffff;
          }
        }
      }
    }
    .service-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      img {
        margin-bottom: 17.5px;
        width: 110px;
        height: 110px;
        border-radius: 10px;
      }
      span {
        padding-bottom: 5px;
      }
    }
  }
  .contact-type {
    margin-top: 15px;
    img {
      margin-right: 20px;
      width: 32px;
      height: 32px;
    }
  }
  .copy-right {
    margin-top: 20px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 0.29px;
    font-size: 14px;
    // color: #ffffff;
    p {
      padding-bottom: 15px;
    }
  }
}
