.supply-chain-login {
  position: relative;
  height: 100%;
  background: rgb(240, 244, 253);
  .back-home {
    position: absolute;
    top: 50px;
    left: 60px;
    width: 244px;
    height: 40px;
    cursor: pointer;
  }
  .login-bg {
    width: 1920px;
    height: 1080px;
  }
  .input-common {
    margin-top: 10px;
    width: 374px;
    height: 40px;
    border-radius: 4px;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #dde2e9;
  }
  .login-title {
    font-size: 22px;
    color: #202023;
    font-weight: bold;
  }
  .login-container {
    position: absolute;
    top: 215px;
    right: 315px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    padding: 50px 40px;
    width: 454px;
    height: 595px;
    border-radius: 30px;
    background: #ffffff;

    .login-type-tab {
      display: flex;
      margin-top: 49px;
      margin-bottom: 20px;
      > div {
        margin-right: 49px;
        padding: 3px 0;
        font-size: 14px;
        font-weight: bold;
        border-bottom: 2px solid transparent;
        color: #606060;
        cursor: pointer;
        &.active {
          color: #175bff;
          border-color: #175bff;
        }
      }
    }
    .input-box {
      display: flex;
      flex-direction: column;
    }
    .login-btn-box {
      margin: 28px 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 374px;
      height: 40px;
      border-radius: 4px;
      background: #1763ff;
      font-size: 14px;
      color: #ffffff;
      font-weight: bold;
      cursor: pointer;
    }
    .forget-password {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 374px;
      font-size: 14px;
      color: #606060;
      .query-pw-tip {
        position: absolute;
        top: 40px;
        left: 0;
        display: flex;
        padding: 10px 0;
        min-width: 258px;
        height: 34px;
        border-radius: 4px;
        background: #f7fcff;
        box-sizing: border-box;
        border: 1px dashed #d6edff;
        &.show-tip {
          display: block;
        }
        &.hide-tip {
          display: none;
        }
        .tip-img {
          margin: 0 9px 0 13px;
          width: 14px;
          height: 14px;
        }
        .close-img {
          margin: 0 18px;
          width: 10px;
          height: 10px;
          cursor: pointer;
        }
        span {
          font-size: 12px;
          &:nth-child(1) {
            color: #202023;
          }
          &:nth-child(1) {
            color: #1763ff;
          }
        }
      }
      .left {
        cursor: pointer;
      }
      .right {
        span {
          &:nth-child(2) {
            cursor: pointer;
            color: #1763ff;
          }
        }
      }
    }
  }
  .form-item {
    margin-bottom: 16px;
  }
  :where(.css-dev-only-do-not-override-12upa3x).ant-form-item .ant-form-item-explain-error {
    color: #e52b2b;
    font-size: 12px;
    padding-top: 6px;
  }
  input::-webkit-input-placeholder {
    color: #909090;
    font-size: 14px;
  }
  input:-moz-placeholder {
    color: #909090;
    font-size: 14px;
  }
  input::-moz-placeholder {
    color: #909090;
    font-size: 14px;
  }
  input:-ms-input-placeholder {
    color: #909090;
    font-size: 14px;
  }
}
