html,
body,
#root {
  height: 100%;
}
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
