.news-detail-page {
  padding-top: 58px;
  background: #fafcff;
  .news-information {
    .news-banner-box {
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .news-top {
      position: relative;
      z-index: 9;
      margin: -41px auto 0;
      width: 1400px;
      height: 160px;
      background: #ffffff;
      box-shadow: 0px 4px 10px 0px rgba(212, 212, 212, 0.3);
      .news-title {
        display: flex;
        flex-direction: column;
        padding: 39px 100px;
        span {
          &:nth-child(1) {
            font-size: 32px;
            color: #3d3d3d;
            font-weight: bold;
          }
          &:nth-child(2) {
            padding: 12px 0;
            font-size: 18px;
            color: #202023;
          }
          &:nth-child(3) {
            width: 25px;
            height: 4px;
            background: #202023;
          }
        }
      }
    }
    .news-content {
      display: flex;
      padding: 24px 361px 73px 264px;
      .association-news {
        margin-right: 63px;
        width: 290px;
        height: 340px;
        // background: #ffffff;
        // box-shadow: 0px 4px 10px 0px rgba(212, 212, 212, 0.3);
        .association-title {
          width: 130px;
          height: 40px;
          background: linear-gradient(105deg, #175bff 11%, #cfbfff 100%);
          box-shadow: 0px 4px 10px 0px rgba(212, 212, 212, 0.3);
          font-size: 18px;
          color: #ffffff;
          text-align: center;
          line-height: 40px;
        }
        .association-list {
          margin-top: 14px;
          font-size: 14px;
          color: #202023;
          line-height: 20px;
          > div {
            margin: 0 15px 0;
            padding: 16px 0;
            border-bottom: 1px solid #f1f1f1;
          }
        }
      }
      .news-detail {
        flex: 1;
        .detail-title {
          padding: 66px 0 28px;
          border-bottom: 1px solid #f1f1f1;
          p {
            &:nth-child(1) {
              padding-bottom: 16px;
              font-size: 18px;
              color: #202023;
              font-weight: bold;
              line-height: 34px;
            }
            &:nth-child(2) {
              font-size: 12px;
              color: #383838;
              line-height: 17px;
            }
          }
        }
        .detail-content {
          padding-top: 24px;
          p {
            font-size: 14px;
            color: #202023;
            line-height: 40px;
          }
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: flex-end;
      padding: 0 361px 58px 0;
      :where(.css-dev-only-do-not-override-1wazalj).ant-pagination
        .ant-pagination-prev
        .ant-pagination-item-link,
      :where(.css-dev-only-do-not-override-1wazalj).ant-pagination
        .ant-pagination-next
        .ant-pagination-item-link {
        background: #ffffff;
      }
      :where(.css-dev-only-do-not-override-1wazalj).ant-pagination .ant-pagination-item {
        background: #ffffff;
      }
      :where(.css-dev-only-do-not-override-1wazalj).ant-pagination .ant-pagination-item-active {
        background: #0256ff;
      }
      :where(.css-dev-only-do-not-override-1wazalj).ant-pagination .ant-pagination-item-active a {
        color: #ffffff;
      }
    }
    .news-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 361px;
      padding-bottom: 480px;
      cursor: pointer;
      .news-footer-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 26px;
        width: 400px;
        height: 110px;
        border-radius: 8px;
        background: #ffffff;
        box-shadow: 0px 4px 10px 0px rgba(234, 234, 234, 0.3);
        .pre-piece {
          display: flex;
          span {
            padding-left: 7px;
            color: #383838;
            font-size: 14px;
          }
          > img {
            width: 16px;
            height: 16px;
          }
        }
        .pre-piece-title {
          margin-top: 20px;
          font-size: 14px;
          color: #000000;
          line-height: 22px;
        }
      }
      .news-footer-middle {
        padding: 0 45px;
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 500;
      }
      .news-footer-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 26px;
        width: 400px;
        height: 110px;
        border-radius: 8px;
        background: #ffffff;
        box-shadow: 0px 4px 10px 0px rgba(234, 234, 234, 0.3);
        .next-piece {
          display: flex;
          span {
            padding-right: 7px;
            color: #383838;
            font-size: 14px;
          }
          > img {
            width: 16px;
            height: 16px;
          }
        }
        .next-piece-title {
          margin-top: 20px;
          font-size: 14px;
          color: #000000;
          line-height: 22px;
        }
      }
    }
  }
}
