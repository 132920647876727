.news-information {
  background: #fafcff;
  .news-banner-box {
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .news-top {
    position: relative;
    z-index: 9;
    margin: -41px auto 0;
    width: 1400px;
    height: 160px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(212, 212, 212, 0.3);
    .news-title {
      display: flex;
      flex-direction: column;
      padding: 39px 100px;
      span {
        &:nth-child(1) {
          font-size: 32px;
          color: #3d3d3d;
          font-weight: bold;
        }
        &:nth-child(2) {
          padding: 12px 0;
          font-size: 18px;
          color: #202023;
        }
        &:nth-child(3) {
          width: 25px;
          height: 4px;
          background: #202023;
        }
      }
    }
    .news-type {
      position: absolute;
      right: 100px;
      top: -42px;
      z-index: 19;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 33px;
      width: 207px;
      height: 202px;
      background: #ffffff;
      box-shadow: 0px 4px 10px 0px rgba(212, 212, 212, 0.3);
      span {
        padding: 15px 0;
        font-size: 14px;
        color: #606060;
        border-bottom: 1px dashed #e5e5e5;
      }
    }
  }
  .news-list {
    padding: 0 360px;
    .news-list-item {
      margin-top: 97px;
      display: flex;
      height: 316px;
      .item-left {
        .news-img {
          width: 513px;
          height: 316px;
        }
      }
      .item-middle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        margin: 0 55px 0 99px;
        background: #ffffff;
        border: 2px solid rgba(229, 229, 229, 0.6);
        span {
          font-size: 28px;
          color: #415388;
          line-height: 20px;
          &:nth-child(2) {
            padding-top: 15px;
            color: #415388;
            font-size: 18px;
          }
        }
      }
      .item-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 316px;
        width: 433px;
        .news-info {
          .news-title {
            font-size: 24px;
            color: #202023;
            line-height: 40px;
          }
          .news-content {
            margin-top: 30px;
            font-size: 14px;
            color: #999999;
            line-height: 20px;
          }
        }
        .news-detail {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          .detail-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 120px 0 0 253px;
            width: 180px;
            height: 40px;
            border-radius: 4px;
            background: linear-gradient(270deg, #1865ff 0%, #33c4fe 100%);
            font-size: 14px;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: flex-end;
      padding: 90px 0 49px;
      :where(.css-dev-only-do-not-override-1wazalj).ant-pagination
        .ant-pagination-prev
        .ant-pagination-item-link,
      :where(.css-dev-only-do-not-override-1wazalj).ant-pagination
        .ant-pagination-next
        .ant-pagination-item-link {
        background: #ffffff;
      }
      :where(.css-dev-only-do-not-override-1wazalj).ant-pagination .ant-pagination-item {
        background: #ffffff;
      }
      :where(.css-dev-only-do-not-override-1wazalj).ant-pagination .ant-pagination-item-active {
        background: #0256ff;
      }
      :where(.css-dev-only-do-not-override-1wazalj).ant-pagination .ant-pagination-item-active a {
        color: #ffffff;
      }
    }
  }
}
