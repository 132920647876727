.market-cooperate {
  padding-top: 58px;
  background: #f5faff;
  .market-banner {
    position: relative;
    .first-content {
      position: absolute;
      top: 230px;
      left: 360px;
      .content-title {
        font-size: 54px;
        font-weight: bold;
        color: #ffffff;
      }
      .content-desc {
        padding: 36px 0 30px;
        font-size: 20px;
        color: #ffffff;
        p {
          padding-bottom: 20px;
        }
      }
    }
  }
  .market-tabs {
    display: flex;
    padding: 38px 455px 30px;
    .tab-item {
      text-align: center;
      font-size: 32px;
      color: #202023;
      line-height: 42px;
      cursor: pointer;
      &.activity {
        color: #1c6fff;
      }
      .img-box {
        width: 231px;
        height: 4px;
        > img {
          margin-top: -10px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .form-container {
    padding: 0 360px 110px;
  }
  .common-form-style {
    padding: 62px 193px 133px 212px;
    width: 1200px;
    // height: 1132px;
    background: #ffffff;
  }
  .submit-success {
    display: flex;
    justify-content: center;
    padding-top: 196px;
    width: 1200px;
    height: 1132px;
    background: #ffffff;
    img {
      width: 356px;
      height: 328px;
    }
  }
  .tip-text {
    display: flex;
    align-items: center;
    padding-bottom: 38px;
    padding-left: 35px;
    > img {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }
    > span {
      font-size: 18px;
      color: #202023;
    }
  }
  .upload-img {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #808080;
    background: linear-gradient(180deg, #f9f9f9 0%, #f4f4f1 100%);
    border: 1px solid #d9d9d9;
    width: 145px;
    height: 38px;
    border-radius: 4px;
    img {
      margin-right: 3px;
      width: 10px;
      height: 10px;
    }
    cursor: pointer;
  }
  .financing-type-input {
    margin-left: 98px;
  }
  .touzibuzhou {
    margin-bottom: 48px;
  }
  .submit-btn {
    margin: 60px 0 0 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 56px;
    border-radius: 4px;
    background: #175bff;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
  }
  :where(.css-dev-only-do-not-override-1wazalj).ant-form-item {
    margin-bottom: 56px;
  }
}
