.supply-chain-register {
  position: relative;
  height: 100%;
  background: rgb(240, 244, 253);
  .back-home {
    position: absolute;
    top: 50px;
    left: 60px;
    width: 244px;
    height: 40px;
    cursor: pointer;
  }
  .login-bg {
    width: 1920px;
    height: 1080px;
  }
  .input-common {
    margin-top: 10px;
    width: 374px;
    height: 40px;
    border-radius: 4px;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #dde2e9;
  }
  .login-title {
    margin-bottom: 30px;
    font-size: 22px;
    color: #202023;
    font-weight: bold;
  }
  .error-tip {
    color: #e52b2b;
    font-size: 12px;
    padding-top: 6px;
  }
  .register-container {
    position: absolute;
    top: 215px;
    right: 315px;
    padding: 50px 40px 26px;
    display: flex;
    flex-direction: column;
    width: 454px;
    border-radius: 30px;
    background: #ffffff;

    .register-btn {
      margin: 64px 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 374px;
      height: 40px;
      border-radius: 4px;
      background: #1763ff;
      font-size: 14px;
      color: #ffffff;
      font-weight: bold;
      cursor: pointer;
    }
    .go-login {
      margin-top: 147px;
      display: flex;
      justify-content: center;
      width: 374px;
      font-size: 14px;
      color: #606060;
      span {
        &:nth-child(2) {
          cursor: pointer;
          color: #1763ff;
        }
      }
    }
  }
  .form-item {
    margin-bottom: 16px;
  }
  input::-webkit-input-placeholder {
    color: #909090;
    font-size: 14px;
  }
  input:-moz-placeholder {
    color: #909090;
    font-size: 14px;
  }
  input::-moz-placeholder {
    color: #909090;
    font-size: 14px;
  }
  input:-ms-input-placeholder {
    color: #909090;
    font-size: 14px;
  }
  :where(.css-dev-only-do-not-override-12upa3x).ant-form-item .ant-form-item-explain-error {
    color: #e52b2b;
    font-size: 12px;
    padding-top: 6px;
  }
}
