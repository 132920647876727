.product-focus {
  .banner-container {
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .first-content {
      position: absolute;
      top: 245px;
      left: 330px;
      .content-title {
        font-size: 54px;
        font-weight: bold;
        color: #202023;
      }
      .content-desc {
        padding: 36px 0 30px;
        font-size: 20px;
        color: #202023;
        p {
          padding-bottom: 20px;
        }
      }
      .btn-container {
        display: flex;
        .join-we {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 46px;
          color: #ffffff;
          font-size: 20px;
          width: 182px;
          height: 55px;
          background: linear-gradient(90deg, #1865ff 0%, #33c4fe 100%);
          border-radius: 4px;
          cursor: pointer;
          > img {
            margin-left: 5px;
            width: 21.5px;
            height: 6px;
          }
        }
        .product-focus {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 182px;
          height: 55px;
          border: 1px solid #1a6cff;
          border-radius: 4px;
          color: #0061ff;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .tabs-container {
    display: flex;
    justify-content: space-between;
    padding: 38px 365px 30px;
    background: #f5faff;
    box-shadow: 0px 4px 10px 0px rgba(229, 237, 255, 0.8);
    .tab-item {
      position: relative;
      text-align: center;
      font-size: 32px;
      color: #202023;
      line-height: 42px;
      cursor: pointer;
      &.activity {
        color: #1c6fff;
      }
      .img-box {
        width: 280px;
        height: 4px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .sub-tab-box {
    position: absolute;
    top: 7px;
    left: -10px;
    &:hover {
      .sub-tab-show {
        display: flex;
      }
    }
    .hover-box {
      width: 290px;
      height: 70px;
      background: transparent;
    }
  }
  .sub-tab {
    display: flex;
    justify-content: space-around;
    padding: 23px 0 19px;
    width: 400px;
    height: 130px;
    background: #ffffff;
    display: none;
    .sub-item {
      display: flex;
      flex-direction: column;
      span {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.88);
        line-height: 22px;
        &:nth-child(1) {
          color: #202023;
          font-size: 16px;
          font-weight: 500;
          cursor: text;
        }
        &:nth-child(2) {
          margin: 14px 0 8px;
          &:hover {
            color: #175bff;
          }
        }
        &:nth-child(3) {
          &:hover {
            color: #175bff;
          }
        }
      }
    }
  }
  .fund-product {
    display: flex;
    justify-content: space-between;
    padding: 125px 360px 109px;
    background: #f5faff;
    height: 700px;
    .product-left {
      margin-top: 110px;
      p {
        font-size: 18px;
        color: #202023;
        line-height: 28px;
        &:nth-child(1) {
          padding-bottom: 42px;
          color: #0061ff;
          font-size: 24px;
        }
      }
    }
    .product-right {
      width: 509px;
      height: 466px;
    }
  }
  .title-common {
    font-size: 32px;
    color: #202023;
    font-weight: 500;
    text-align: center;
  }
  .common-text {
    font-size: 18px;
    color: #606060;
    line-height: 28px;
  }
  .financing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 72px 300px 97px;
    background: url('../../images/dingdanrongzi.png') no-repeat;
    background-size: 100% 100%;
    &.lvjin-container {
      background: url('../../images/lvjinjinrong.png') no-repeat;
    }
    &.pledge-container {
      background: #ffffff;
    }
    &.accounts-container {
      padding: 100px 360px 174px;
      background: #f5faff;
    }
    &.service-container {
      padding: 100px 360px 75px;
      background: #ffffff;
      .service-desc {
        margin: 30px 0 50px;
        p {
          text-align: center;
          &:nth-child(1) {
            padding-bottom: 10px;
          }
        }
      }
      .service-advantage {
        .advantage-title {
          width: 129px;
          height: 78px;
        }
        .advantage-content {
          display: flex;
          justify-content: space-between;
          margin-top: 80px;
          > img {
            &:nth-child(1) {
              margin-right: 60px;
            }
            width: 570px;
            height: 410px;
          }
        }
      }
    }
    &.count-container {
      padding: 100px 0 119px;
      background: #f5faff;
    }
    .financing-desc {
      margin: 30px 0 100px;
    }
    .financing-advantage {
      display: flex;
      justify-content: space-between;
      > img {
        width: 432px;
        height: 495px;
      }
    }
    .pledge-advantage {
      display: flex;
      justify-content: space-between;
      > img {
        margin-left: 48px;
        width: 360px;
        height: 380px;
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
    .accounts-advantage {
      display: flex;
      justify-content: space-between;
      > img {
        margin-left: 60px;
        width: 360px;
        height: 380px;
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
    .count-advantage {
      display: flex;
      justify-content: space-between;
      > img {
        margin-left: -48px;
        width: 384px;
        height: 395px;
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
    .lvjin-advantage {
      display: flex;
      justify-content: space-between;
      > img {
        width: 432px;
        height: 495px;
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
    .success-temp {
      margin-top: 80px;
      > img {
        width: 1257px;
        height: 323px;
      }
    }
    .lvjin-temp {
      margin-top: 30px;
      > img {
        width: 1260px;
        height: 323px;
      }
    }
    .liu-cheng-xiang-xi {
      margin: 50px 0 78px;
      font-size: 32px;
      color: #202023;
      font-weight: 500;
    }
    .liu-cheng {
      margin-bottom: 50px;
      width: 1216px;
      height: 490px;
    }
  }
}
