.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 113px 0 280px;
  width: 100%;
  height: 74px;
  background: #ffffff;
  .logo-container {
    width: 244px;
    height: 40px;
    img {
      width: 244px;
      height: 40px;
    }
  }
  .tabs-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 162px 0 207px;
    font-size: 18px;
    color: #19164f;
    cursor: pointer;
    height: 100%;
    span {
      display: flex;
      align-items: center;
      height: 100%;
      border-bottom: 4px solid transparent;
      &.active {
        color: #175bff;
        border-color: #175bff;
      }
    }
  }
  .login-container {
    display: flex;
    align-items: center;
    color: #ffffff;
    width: 147px;
    height: 44px;
    background: #175bff;
    font-size: 18px;
    cursor: pointer;
    .login-left {
      flex: 1;
      text-align: center;
      border-right: 1px solid #ffffff;
    }
    .login-right {
      flex: 1;
      text-align: center;
    }
  }
  .user-name {
    font-size: 20px;
    color: #19164f;
    line-height: 24px;
    cursor: pointer;
  }
}
:where(.css-dev-only-do-not-override-12upa3x).ant-popover .ant-popover-inner {
  // background: #999;
  background: #ffffff;
}
.user-info {
  .info-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    &.system-entry {
      cursor: pointer;
    }
    &:nth-child(1) {
      margin-top: 10px;
    }
    .item-title {
      padding-right: 8px;
      font-size: 14px;
      color: #202023;
    }
    .item-content {
      font-size: 14px;
      color: #606060;
    }
    .layout-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 32px;
      border-radius: 4px;
      background: #175bff;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
      img {
        margin-left: 5px;
        width: 13px;
        height: 13px;
      }
    }
  }
}
