.content-container {
  padding-top: 58px;
  .content-01 {
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .first-content {
      position: absolute;
      top: 210px;
      left: 360px;
      .content-title {
        font-size: 54px;
        font-weight: bold;
        color: #202023;
      }
      .content-desc {
        padding: 35px 0 30px;
        font-size: 18px;
        color: #202023;
        p {
          padding-bottom: 20px;
        }
      }
      .btn-container {
        display: flex;
        .join-we-box {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 46px;
          color: #ffffff;
          font-size: 20px;
          width: 182px;
          height: 55px;
          background: linear-gradient(90deg, #1865ff 0%, #33c4fe 100%);
          border-radius: 4px;
          cursor: pointer;
          > img {
            margin-left: 5px;
            width: 21.5px;
            height: 6px;
          }
        }
        .product-focus {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 182px;
          height: 55px;
          border: 1px solid #1a6cff;
          border-radius: 4px;
          color: #0061ff;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .content-02 {
    padding: 100px 357px 0 360px;
    background: #f5faff;
    height: 960px;
    .content-02-title {
      color: #202023;
      font-size: 32px;
      font-weight: 500;
      text-align: center;
    }
    .content-middle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 80px;
      .middle-left {
        margin-right: 102px;
        p {
          &:nth-child(1) {
            color: #0061ff;
            font-size: 24px;
            line-height: 40px;
          }
          &:nth-child(2) {
            padding-top: 42px;
            color: #202023;
            font-size: 18px;
            line-height: 44px;
          }
          &:nth-child(3) {
            padding-top: 34px;
            color: #606060;
            font-size: 18px;
            line-height: 36px;
          }
        }
        .content-02-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 76px;
          width: 220px;
          height: 55px;
          border-radius: 4px;
          background: linear-gradient(90deg, #1865ff 0%, #33c4fe 100%);
          color: #ffffff;
          font-size: 18px;
          cursor: pointer;
        }
      }
      .icon-01 {
        width: 440px;
        height: 581px;
      }
    }
  }
  .content-03 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    .content-03-title {
      padding-top: 100px;
      color: #202023;
      font-size: 32px;
      text-align: center;
      font-weight: 500;
    }
    .credit-desc {
      margin-top: 78px;
      margin-bottom: 152px;
      position: relative;
      .desc-bg {
        width: 1225px;
        height: 570px;
      }
      .desc-content {
        position: absolute;
        left: 58px;
        top: 120px;
        p {
          color: #ffffff;
          font-size: 18px;
          line-height: 50px;
        }
        .credit-btn-container {
          margin-top: 64px;
          display: flex;
          cursor: pointer;
          .pro-focus {
            margin-right: 38px;
            text-align: center;
            line-height: 55px;
            width: 220px;
            height: 55px;
            border-radius: 4px;
            background: #ffffff;
            color: #000000;
            font-size: 18px;
          }
          .market {
            text-align: center;
            line-height: 55px;
            width: 220px;
            height: 55px;
            border-radius: 4px;
            font-size: 18px;
            color: #ffffff;
            border: 1px solid #ffffff;
          }
        }
      }
    }
  }
  .content-04 {
    padding: 100px 365px 164px;
    background: #f5faff;
    .content-04-title {
      color: #1a1c3b;
      font-size: 32px;
      font-weight: 500;
      text-align: center;
    }
    .our-advantage {
      margin-top: 134px;
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        img {
          width: 305px;
          height: 248px;
        }
        p {
          &:nth-child(2) {
            padding: 49px 0 30px;
            color: #202023;
            font-size: 24px;
            font-weight: bold;
          }
          &:nth-child(3) {
            color: #606060;
            font-size: 16px;
            line-height: 38px;
          }
          &:nth-child(4) {
            color: #606060;
            font-size: 16px;
            line-height: 38px;
          }
        }
      }
    }
  }
  .invest-hope {
    padding: 100px 0 155px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('../../images/hope-bg.png') no-repeat;
    background-size: 100% 100%;
    .hope-title {
      font-size: 32px;
      color: #202023;
      font-weight: 500;
      line-height: 68px;
      text-align: center;
    }
    .hope-item {
      margin-top: 110px;
      img {
        width: 1200px;
        height: 700px;
      }
    }
  }
  .content-05 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f5faff;
    .content-04-title {
      color: #202023;
      font-size: 32px;
      font-weight: 500;
      text-align: center;
      line-height: 68px;
      padding-top: 100px;
    }
    .sucess-case {
      padding: 24px 0 104px;
      font-size: 18px;
      color: rgba(32, 32, 35, 0.6);
      text-align: center;
    }
    .chain-box {
      position: relative;
      .chain-bg {
        width: 1200px;
        height: 500px;
      }
      .chain-content {
        position: absolute;
        left: 40px;
        top: 72px;
        > img {
          width: 240px;
          height: 300px;
          margin-left: 53px;
          &:nth-child(1) {
            margin-left: 0;
          }
        }
      }
    }
    .cooperate-container {
      position: relative;
      background: #ffffff;
      width: 100%;
      height: 903px;
      .cooperate-bg {
        position: absolute;
        top: -200px;
        left: 0;
        z-index: 9;
      }
      .cooperate-business-box {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .jion-us-btn {
          margin-top: 51px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 220px;
          height: 54px;
          border-radius: 4px;
          font-size: 32px;
          color: #202023;
          font-weight: 500;
          > img {
            margin-left: 10px;
            width: 19px;
            height: 9px;
          }
        }
        .business-img {
          margin-top: 60px;
          width: 1200px;
        }
      }
    }
  }
}
