.join-we {
  padding-top: 58px;
  .join-we-banner {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .work-env {
    padding: 100px 280px 163px;
    background: #ffffff;
    .env-title {
      font-size: 32px;
      color: #202023;
      font-weight: 500;
      line-height: 42px;
      text-align: center;
    }
    .show-env {
      display: flex;
      justify-content: space-between;
      margin-top: 95px;
      img {
        width: 453px;
        height: 513px;
      }
    }
  }
  .work-welfare {
    position: relative;
    .welfare-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 100px 497px 170px;
      .welfare-title {
        font-size: 32px;
        color: #ffffff;
        font-weight: 500;
        text-align: center;
      }
      .show-welfare {
        margin-top: 95px;
        display: flex;
        justify-content: space-between;
        img {
          width: 240px;
          height: 319px;
        }
      }
    }
  }
  .work-post {
    padding: 100px 360px 200px;
    background: #ffffff;
    .post-title {
      font-size: 32px;
      color: #202023;
      font-weight: 500;
      text-align: center;
    }
    .email-address {
      padding: 16px 0 80px;
      color: rgba(32, 32, 35, 0.6);
      font-size: 18px;
      text-align: center;
    }
    .post-table {
      .post-table-title {
        display: flex;
        padding: 16px 0 16px 40px;
        width: 1200px;
        height: 56px;
        border-radius: 8px;
        background: #e2ebfd;
        font-size: 18px;
        color: #202023;
        span {
          &:nth-child(1) {
            width: 293px;
          }
          &:nth-child(2) {
            width: 427px;
          }
          &:nth-child(3) {
            width: 288px;
          }
        }
      }
      .post-table-item {
        display: flex;
        padding: 16px 0 16px 40px;
        width: 1200px;
        height: 56px;
        border-radius: 8px;
        background: #ffffff;
        font-size: 14px;
        color: #202023;
        border-bottom: 1px dashed #d8d8d8;
        span {
          &:nth-child(1) {
            width: 293px;
          }
          &:nth-child(2) {
            width: 427px;
          }
          &:nth-child(3) {
            width: 288px;
          }
        }
      }
    }
  }
}
