.system-page {
  position: relative;
  background: rgb(240, 244, 253);
  height: 100%;
  display: flex;
  justify-content: center;
  .go-home {
    position: absolute;
    top: 80px;
    left: 100px;
    font-size: 24px;
    line-height: 32px;
    cursor: pointer;
  }
  .system-type {
    display: flex;
    padding-top: 280px;
    .type-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 380px;
      border-radius: 20px;
      background: #ffffff;
      color: #1a1a1a;
      cursor: pointer;
      &:nth-child(2) {
        margin: 0 60px;
      }
      &:hover {
        background: #175bff;
        color: #ffffff;
      }
      img {
        width: 300px;
        height: 300px;
      }
      span {
        margin-top: -10px;
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
